
import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../store";
import { APP_INFO } from "../interface/public";
import GoOcc from "../components/GoOcc.vue";

@Component({
  name: "CommonHeader",
  components: {
    GoOcc,
  },
})
export default class CommonHeader extends Vue {
  activeApp: APP_INFO;
  userName = "";
  appList: any[] = [];

  created(): void {
    const currentAppObj = localStorage.getItem("currentApp") || "{}";
    this.activeApp = JSON.parse(currentAppObj);
    const auth_jwt_decoded = localStorage.getItem("auth_jwt_decoded") || "";
    if (auth_jwt_decoded)
      this.userName = JSON.parse(auth_jwt_decoded).displayName || "";
    this.appList = store.state.appList;
  }
  /**
   * Switch app when clicking app dropdown item
   * @params {Object} command: the app object
   * @returns void
   */
  public switchApp(command: APP_INFO): void {
    store.commit("setCurrentApp", command);
    // if (this.$route.path !== "/") {
    //   this["$router"].push("/");
    // }
  }

  @Watch("$store.state.currentApp", { deep: true, immediate: true })
  onCurrentAppChanged(val: APP_INFO): void {
    this.activeApp = val;
    this.$forceUpdate();
  }
  /**
   * log off the user and clears the local storage
   * @returns void
   */
  public logoff(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.$router.push("/logout");
  }
}
